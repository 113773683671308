import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "button"];

  declare readonly sourceTarget: HTMLInputElement;
  declare readonly buttonTarget: HTMLButtonElement;

  connect(): void {
    if (!this.sourceTarget) {
      throw new Error("Missing source target");
    }

    if (!this.buttonTarget) {
      throw new Error("Missing button target");
    }
  }

  copy(event: Event) {
    event.preventDefault();
    const url = this.sourceTarget.value;

    this.sourceTarget.select();
    this.sourceTarget.setSelectionRange(0, url.length);

    navigator.clipboard
      .writeText(url)
      .then(() => {
        const originalValue = this.buttonTarget.value;
        this.buttonTarget.value = "Copied!";
        setTimeout(() => {
          this.buttonTarget.value = originalValue;
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }
}
