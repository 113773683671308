import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message", "messageContent", "chatroom", "chatroomLabel"];
  static values = { currentUserId: Number };

  connect() {
    this._setActiveChatroom(this.chatroomTargets[0].dataset.chatroomId);
  }

  setActiveChatroom(event) {
    const activeChatroomId = event.params.chatroomId;
    this._setActiveChatroom(activeChatroomId);
  }

  messageTargetConnected(element) {
    const messageContentElement = element.querySelector(
      "[data-message-content]",
    );

    if (element.dataset.messageUserId == this.currentUserIdValue) {
      messageContentElement.classList.add("bg-blue-500");
      element.classList.add("text-right");
    } else {
      messageContentElement.classList.add("bg-gray-500");
      element.classList.add("text-left");
    }

    element.scrollIntoView();
  }

  close() {
    this.element.classList.add("hidden");
  }

  submit(event) {
    if (event.key == "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.target.form.requestSubmit();
    }
  }

  _setActiveChatroom(chatroomId) {
    const allChatroomElements = Array.from(this.chatroomTargets);
    const activeChatroomElement = allChatroomElements.find(
      (element) => element.dataset.chatroomId == chatroomId,
    );

    allChatroomElements.forEach((chatroomElement) => {
      if (chatroomElement == activeChatroomElement) {
        chatroomElement.classList.remove("hidden");
      } else {
        chatroomElement.classList.add("hidden");
      }
    });

    this.chatroomLabelTargets.forEach((chatroomLabel) => {
      const chatroomLabelId = chatroomLabel.dataset.chatChatroomIdParam;

      if (chatroomLabelId == chatroomId) {
        chatroomLabel.classList.add("bg-gray-200", "font-medium");
      } else {
        chatroomLabel.classList.remove("bg-gray-200", "font-medium");
      }
    });
  }
}
