import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "selectedPractitioner",
    "template",
    "emptyState",
    "table",
  ];
  static values = {
    children: Number,
  };

  connect() {
    if (this.childrenValue > 0) {
      this.showTable();
    } else {
      this.showEmptyState();
    }
  }

  add() {
    this.selectedPractitionerIds.forEach((id) => {
      if (this.isDuplicate(id)) return;

      fetch(`/super_admin/users/${id}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.appendDataToContainer(data, id);
          this.showTable();
          this.hideEmptyState();
        });
    });
  }

  remove(e) {
    e.preventDefault();
    e.currentTarget.closest("tr").remove();
  }

  appendDataToContainer(data, id) {
    const node = this.templateTarget.content.firstElementChild.cloneNode(true);
    node.querySelector(".qualified-practitioner-id").value = id;
    node.querySelector(".qualified-practitioner-name").innerHTML = data.name;
    node.querySelector(".qualified-practitioner-email").innerHTML = data.email;
    node.querySelector(".qualified-practitioner-institution").innerHTML =
      data.institution;
    node.querySelector(".qualified-practitioner-tier").innerHTML = data.tiers;
    this.tableTarget.querySelector(".empty-state").classList.add("hidden");
    this.tableTarget
      .querySelector(".table-container")
      .classList.remove("hidden");
    this.containerTarget.appendChild(node);
  }

  showTable() {
    this.tableTarget.classList.remove("hidden");
  }

  showEmptyState() {
    this.emptyStateTarget.classList.remove("hidden");
  }

  hideEmptyState() {
    this.emptyStateTarget.classList.add("hidden");
  }

  isDuplicate(id) {
    return (
      this.tableTarget.querySelector(
        `.qualified-practitioner-id[value='${id}']`,
      ) !== null
    );
  }

  get selectedPractitionerIds() {
    return Array.from(this.selectedPractitionerTarget.options).map(
      (option) => option.value,
    );
  }
}
