import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video"];

  updateVideoTime(event) {
    const timestampInSeconds = parseInt(event.target.dataset.timestamp);
    this.videoTarget.currentTime = timestampInSeconds;
  }
}
