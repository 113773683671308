import { Controller } from "@hotwired/stimulus";
import videojs from "video.js";

// Connects to data-controller="video-synchronizer"
export default class extends Controller {
  static targets = ["primaryVideo", "secondaryVideo"];
  static values = {
    secondaryVideoMuted: Boolean,
  };

  connect() {
    this.primaryPlayerLoaded = false;
    this.secondaryPlayerLoaded = false;

    this.primaryPlayer = videojs(this.primaryVideoTarget, {
      controls: false,
    });

    this.secondaryPlayer = videojs(this.secondaryVideoTarget, {
      controls: false,
      muted: this.secondaryVideoMutedValue,
    });

    this.primaryPlayer.on("loadeddata", () => {
      this.primaryPlayerLoaded = true;
      this._checkIfBothPlayersLoaded();
    });

    this.secondaryPlayer.on("loadeddata", () => {
      this.secondaryPlayerLoaded = true;
      this._checkIfBothPlayersLoaded();
    });

    this.primaryPlayer.on("play", () => {
      this.secondaryPlayer.currentTime(this.primaryPlayer.currentTime());
      this.secondaryPlayer.play();
    });

    this.primaryPlayer.on("pause", () => {
      this.secondaryPlayer.currentTime(this.primaryPlayer.currentTime());
      this.secondaryPlayer.pause();
    });

    this.primaryPlayer.on("timeupdate", () => {
      if (this._playerTimeDifference() > 0.25) {
        this.secondaryPlayer.currentTime(this.primaryPlayer.currentTime());
      }
    });
  }

  _checkIfBothPlayersLoaded() {
    if (this.primaryPlayerLoaded && this.secondaryPlayerLoaded) {
      this.primaryPlayer.controls(true);
    }
  }

  _playerTimeDifference() {
    return Math.abs(
      this.primaryPlayer.currentTime() - this.secondaryPlayer.currentTime(),
    );
  }
}
