import { Controller } from "@hotwired/stimulus";

import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["editor", "autoScroll"];
  static values = {
    id: Number,
    meetingId: String,
    initialVersion: Number,
    captioningChannel: String,
  };

  initialize() {
    this.version = this.initialVersionValue;
    this.scrollEditorDown();

    this.subscription = consumer.subscriptions.create(
      {
        channel: this.captioningChannelValue,
        meeting_id: this.meetingIdValue,
        transcript_id: this.idValue,
      },
      {
        connected: this.cableConnected.bind(this),
        received: this.cableReceived.bind(this),
      },
    );
  }

  disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  cableConnected() {
    if (this.version > 0) {
      this.subscription.perform("sync_versions", { version: this.version });
    } else {
      this.subscription.perform("initial_transcript");
    }
  }

  cableReceived(data) {
    switch (data.type) {
      case "op":
        return this.operationsReceived(data.message);
      case "init":
        return this.initializeTranscript(data);
      default:
        console.warn(`Can't process message with type ${data.type}`);
    }
  }

  initializeTranscript(data) {
    this.editorTarget.value = data.content;
    this.version = data.version;
  }

  operationsReceived(operations) {
    operations = operations.filter(
      (operation) => operation.version >= this.version,
    );

    if (operations.length == 0) return;

    let firstOperation = operations[0];
    let lastOperation = operations[operations.length - 1];

    if (firstOperation.version !== this.version) {
      return this.subscription.perform("sync_versions", {
        version: this.version,
      });
    }

    operations.forEach((operation) => {
      this.apply(operation);
    });

    this.version = lastOperation.version + 1;
  }

  apply(operation) {
    let content = this.editorTarget.value;
    const { kind, data } = operation;

    switch (kind) {
      case "insert": {
        const { offset, text } = data;
        let newContent = content.substring(0, offset) + text;

        if (offset < content.length) {
          newContent += content.substring(offset);
        }

        this.editorTarget.value = newContent;

        break;
      }
      case "remove": {
        const { offset, text } = data;
        let newContent = content.substring(0, offset);

        if (offset + text.length < content.length) {
          newContent += content.substring(offset + text.length);
        }

        this.editorTarget.value = newContent;

        break;
      }
    }

    if (this.autoScroll) {
      this.scrollEditorDown();
    }

    return this;
  }

  scrollEditorDown() {
    this.editorTarget.scrollTop = this.editorTarget.scrollHeight;
  }

  get autoScroll() {
    if (this.hasAutoScrollTarget) {
      return this.autoScrollTarget.checked;
    } else {
      return true;
    }
  }
}
