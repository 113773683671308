import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container"];

  add() {
    this.containerTarget.appendChild(
      this.templateTarget.content.firstElementChild.cloneNode(true),
    );
  }

  remove(e) {
    e.currentTarget.closest("fieldset").remove();
  }
}
