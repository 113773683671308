import { Controller } from "@hotwired/stimulus";
import Litepicker from "litepicker";

export default class extends Controller {
  static targets = ["date"];

  connect() {
    this.dateTargets.forEach((element) => {
      new Litepicker({
        element: element,
        format: "MMM D, YYYY",
        minDate: Date.now(),
        firstDay: 7,
      });
    });
  }
}
