import { Application } from "@hotwired/stimulus";
import { browserTracingIntegration, replayIntegration } from "@sentry/browser";
import Flatpickr from "stimulus-flatpickr";

import initializeSentry from "@/utils/sentry";

const application = Application.start();
initializeSentry([replayIntegration(), browserTracingIntegration()]);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

application.register("flatpickr", Flatpickr);

export { application };
