import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selector", "onsite", "remote"];
  static values = { selected: String };

  connect() {
    this._updateInterface();
  }

  update(event) {
    this.selectedValue = event.currentTarget.value;
    this._updateInterface();
  }

  _updateInterface() {
    this._hideAll();

    switch (this.selectedValue) {
      case "hybrid":
        this._showAll();
        break;
      case "remote":
        this._showRemote();
        break;
      case "onsite":
        this._showOnsite();
        break;
      default:
        this._hideAll();
    }
  }

  _showOnsite() {
    this.onsiteTarget.classList.remove("hidden");
  }

  _showRemote() {
    this.remoteTarget.classList.remove("hidden");
  }

  _showAll() {
    this.onsiteTarget.classList.remove("hidden");
    this.remoteTarget.classList.remove("hidden");
  }

  _hideAll() {
    this.onsiteTarget.classList.add("hidden");
    this.remoteTarget.classList.add("hidden");
  }
}
