import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "blankAvatar",
    "currentAvatar",
    "fileInput",
    "newAvatar",
    "removeAvatarInput",
  ];

  connect() {
    const self = this;

    this.fileInputTarget.addEventListener("change", function (_e) {
      if (this.files && this.files[0]) {
        const reader = new FileReader();

        reader.onload = function (e) {
          self.newAvatarTarget.querySelector("img").src = e.target.result;

          self.blankAvatarTarget.classList.add("hidden");
          self.currentAvatarTarget.classList.add("hidden");
          self.newAvatarTarget.classList.remove("hidden");
        };

        reader.readAsDataURL(this.files[0]);

        self.removeAvatarInputTarget.value = false;
      }
    });
  }

  remove() {
    this.blankAvatarTarget.classList.remove("hidden");
    this.currentAvatarTarget.classList.add("hidden");
    this.newAvatarTarget.classList.add("hidden");

    this.removeAvatarInputTarget.value = true;
  }
}
