import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["group", "remove"];

  connect() {
    this.renderButton();
  }

  create() {
    this.totalFields++;
    this.renderButton();

    var fieldEntry = this.createFieldEntry();
    this.group.appendChild(fieldEntry);
  }

  createFieldEntry() {
    var fieldEntry = document.createElement("div");
    fieldEntry.dataset.target = "field.entry";
    fieldEntry.className = "form-grid";

    var nameField = this.createField("Name");
    var emailField = this.createField("Email");
    var phoneField = this.createField("Phone");

    fieldEntry.appendChild(nameField);
    fieldEntry.appendChild(emailField);
    fieldEntry.appendChild(phoneField);

    return fieldEntry;
  }

  destroy() {
    this.totalFields--;
    this.renderButton();

    this.groupTarget.lastChild.remove();
  }

  createField(name) {
    var container = document.createElement("div");
    container.className = "form-grid__cell--30";

    var inputLabel = document.createElement("label");
    inputLabel.innerText = name;

    var inputField = document.createElement("input");
    inputField.placeholder = name;
    inputField.name = `interpreting_session[customer_info][]${name.toLowerCase()}`;

    container.appendChild(inputLabel);
    container.appendChild(inputField);

    return container;
  }

  renderButton() {
    if (this.totalFields > 0) {
      this.removeTarget.style.display = "inline-block";
    } else {
      this.removeTarget.style.display = "none";
    }
  }

  get group() {
    return this.groupTarget;
  }

  get totalFields() {
    return this.data.get("total");
  }

  set totalFields(value) {
    return this.data.set("total", value);
  }
}
