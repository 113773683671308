import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["credentials", "topic"];
  static values = {
    disabled: Boolean,
  };

  abortController = null;

  connect() {
    if (this.topicTarget.value) {
      this._call(this.topicTarget.value);
    }
  }

  disconnect() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }

  onTopicChange(event) {
    if (!this.disabledValue) {
      this._call(event.target.value);
    }
  }

  updateChoices(data, selectedOptions) {
    var event = new Event("choices:updateChoices");
    event.data = data.map((d) => {
      return {
        value: d.id,
        label: d.title,
        selected: selectedOptions.some((o) => o.value === d.id.toString()),
      };
    });
    this.credentialsTarget.dispatchEvent(event);
  }

  _call(topicId) {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.abortController = new AbortController();
    const self = this;
    const selectedOptions = Array.from(this.credentialsTarget.selectedOptions);

    fetch(`/topics/${topicId}/credentials`, {
      signal: this.abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        self.updateChoices(data, selectedOptions);
      });
  }
}
