import { Controller } from "@hotwired/stimulus";

type TurboBeforeMorphAttributeEvent = CustomEvent<{
  attributeName: string;
  mutationType: "updated" | "removed";
}>;

export default class LiveTimeController extends Controller {
  static values = {
    seconds: Number,
  };

  declare readonly secondsValue: number;
  initialTimestamp!: number;

  static instances: LiveTimeController[] = [];
  static timer: ReturnType<typeof setInterval> | null = null;

  connect() {
    this.initialTimestamp = Date.now() - this.secondsValue * 1000;
    this.updateTime();
    LiveTimeController.startGlobalTimer();
    LiveTimeController.instances.push(this);
  }

  disconnect() {
    const index = LiveTimeController.instances.indexOf(this);
    if (index !== -1) {
      LiveTimeController.instances.splice(index, 1);
    }

    if (LiveTimeController.instances.length === 0) {
      LiveTimeController.stopGlobalTimer();
    }
  }

  beforeMorphAttribute(event: TurboBeforeMorphAttributeEvent) {
    if (event.detail.attributeName !== "data-live-time-seconds-value") {
      return;
    }

    if (event.detail.mutationType === "removed") {
      this.disconnect();
    } else {
      this.disconnect();
      this.connect();
    }
  }

  updateTime() {
    const elapsedTime = Math.round((Date.now() - this.initialTimestamp) / 1000);
    const hours = Math.floor(elapsedTime / 3600);
    const minutes = Math.floor((elapsedTime % 3600) / 60);
    const seconds = elapsedTime % 60;

    if (hours > 24) {
      this.element.textContent = "> 24h";
    } else {
      this.element.textContent = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    }
  }

  pad(number: number) {
    return number.toString().padStart(2, "0");
  }

  static startGlobalTimer() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.instances.forEach((instance) => instance.updateTime());
      }, 1000);
    }
  }

  static stopGlobalTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
