import { Controller } from "@hotwired/stimulus";

/*
  This controller is responsible for allowing a password field to be toggled between text and password mode.
*/
export default class PasswordController extends Controller {
  static targets = ["field"];

  declare readonly hasFieldTarget: boolean;
  declare readonly fieldTarget: HTMLInputElement;

  connect() {
    if (!this.hasFieldTarget) {
      throw new Error("No field target found");
    }
  }

  togglePassword(event: Event) {
    const toggleLink = event.currentTarget as HTMLElement;

    if (this.fieldTarget.type === "password") {
      this.fieldTarget.type = "text";
      toggleLink.textContent = "Hide password";
    } else {
      this.fieldTarget.type = "password";
      toggleLink.textContent = "Show password";
    }
  }
}
