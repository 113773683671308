import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notice"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.dismiss();
    }, 4000);
  }

  dismiss() {
    this.element.style.bottom = "-50px";
  }
}
