import { Controller } from "@hotwired/stimulus";

export default class PasswordToggleController extends Controller {
  static targets = ["field", "eyeIcon", "eyeSlashIcon"];
  declare readonly fieldTarget: HTMLInputElement;
  declare readonly eyeIconTarget: Element;
  declare readonly eyeSlashIconTarget: Element;
  private showPassword = false;

  connect(): void {
    if (!this.fieldTarget) {
      throw new Error("fieldTarget not found");
    }

    if (!this.eyeIconTarget) {
      throw new Error("eyeIconTarget not found");
    }

    if (!this.eyeSlashIconTarget) {
      throw new Error("eyeSlashIconTarget not found");
    }

    this.showPassword = false;

    this.render();
  }

  toggle(): void {
    this.showPassword = !this.showPassword;

    this.render();
  }

  render(): void {
    if (this.showPassword) {
      this.fieldTarget.setAttribute("type", "text");
      this.eyeIconTarget.classList.add("hidden");
      this.eyeSlashIconTarget.classList.remove("hidden");
    } else {
      this.fieldTarget.setAttribute("type", "password");
      this.eyeIconTarget.classList.remove("hidden");
      this.eyeSlashIconTarget.classList.add("hidden");
    }
  }
}
