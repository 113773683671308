import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

import {
  formatTime,
  generateMinuteSeries,
  getCurrentMinutes,
  parseTime,
  timeDifference,
} from "../utils/time";

class TimeDropdown {
  declare input: HTMLInputElement;
  declare times: ReturnType<typeof generateMinuteSeries>;
  declare defaultMinutes: number;
  declare timesEl: HTMLDivElement;
  declare popperInstance: ReturnType<typeof createPopper>;
  declare onSelect: (minutes: number) => void;
  declare minimumComparisonMinutes: number | null;
  declare highlightedMinutes: number | null;

  constructor(input: HTMLInputElement, onSelect: (minutes: number) => void) {
    this.input = input;
    this.times = [];
    this.minimumComparisonMinutes = null;
    this.defaultMinutes = 0;
    this.onSelect = onSelect;
    this.highlightedMinutes = null;

    this.timesEl = document.createElement("div");
    this.timesEl.setAttribute("data-time-picker", "true");
    this.render();

    this.input.addEventListener("focus", () => {
      this.show();
      this.scrollToMatchingOrDefault();
      this.initializeHighlightedMinutes();
    });
    this.input.addEventListener("click", () => {
      this.show();
      this.scrollToMatchingOrDefault();
      this.initializeHighlightedMinutes();
    });
    this.input.addEventListener("blur", (e) => {
      this.selectElement(e.relatedTarget as HTMLDivElement);
      this.hide();
    });
    this.input.addEventListener("input", (_e) => {
      this.show();
      const matchedMinutes = this.getMinutesMatchingInput();

      if (matchedMinutes) {
        this.getOptionElementByMinutes(matchedMinutes)?.scrollIntoView();
        this.highlightedMinutes = matchedMinutes;
        this.renderHighlight();
      }
    });
    // Watch for escape key to close the dropdown
    this.input.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.hide();
      }
    });
    // Watch for up arrow key to highlight the previous option
    this.input.addEventListener("keydown", (e) => {
      if (e.key === "ArrowUp") {
        e.preventDefault();

        let currentHighlightedMinutes = this.highlightedMinutes;

        if (currentHighlightedMinutes === null) {
          currentHighlightedMinutes =
            this.times.find((times) => {
              return times >= this.defaultMinutes;
            }) || 0;
        }

        const currentIndex = this.times.indexOf(currentHighlightedMinutes);
        const nextIndex = currentIndex > 0 ? currentIndex - 1 : 0;

        const nextMinutes = this.times[nextIndex];
        this.highlightedMinutes = nextMinutes;
        this.renderHighlight();
        this.scrollToHighlighted();
      }
    });
    // Watch for down arrow key to highlight the next option
    this.input.addEventListener("keydown", (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();

        let currentHighlightedMinutes = this.highlightedMinutes;

        if (currentHighlightedMinutes === null) {
          currentHighlightedMinutes =
            this.times.find((times) => {
              return times >= this.defaultMinutes;
            }) || 0;
        }

        const currentIndex = this.times.indexOf(currentHighlightedMinutes);
        const nextIndex =
          currentIndex < this.times.length - 1
            ? currentIndex + 1
            : this.times.length - 1;

        const nextMinutes = this.times[nextIndex];
        this.highlightedMinutes = nextMinutes;
        this.renderHighlight();
        this.scrollToHighlighted();
      }
    });
    // When pressing enter, select the highlighted option
    this.input.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        e.preventDefault();

        if (this.highlightedMinutes) {
          this.input.value = formatTime(this.highlightedMinutes);
          this.onSelect(this.highlightedMinutes);
          this.hide();
        }
      }
    });
  }

  initializeHighlightedMinutes() {
    const matchedMinutes = this.getMinutesMatchingInputExactly();

    if (matchedMinutes !== null && matchedMinutes !== undefined) {
      this.highlightedMinutes = matchedMinutes;
    } else {
      this.highlightedMinutes = null;
    }

    this.renderHighlight();
  }

  scrollToHighlighted() {
    if (this.highlightedMinutes === null) return;

    const highlightedOptionElement = this.getOptionElementByMinutes(
      this.highlightedMinutes,
    );

    if (!highlightedOptionElement) return;

    const highlightedOptionElementRect =
      highlightedOptionElement.getBoundingClientRect();
    const timesElRect = this.timesEl.getBoundingClientRect();

    const isHighlightedOptionElementInView =
      highlightedOptionElementRect.top >= timesElRect.top &&
      highlightedOptionElementRect.bottom <= timesElRect.bottom &&
      highlightedOptionElementRect.left >= timesElRect.left &&
      highlightedOptionElementRect.right <= timesElRect.right;

    if (!isHighlightedOptionElementInView) {
      highlightedOptionElement.scrollIntoView({
        behavior: "instant",
        block: "nearest",
      });
    }
  }

  scrollToMatchingOrDefault() {
    const matchedMinutes = this.getMinutesMatchingInput();

    if (matchedMinutes) {
      this.scrollToElementWithinParent(
        this.getOptionElementByMinutes(matchedMinutes),
        true,
      );
    } else {
      // There's nothing that matches the value, so we'll just use the default
      const closestMinutes = this.times.find((times) => {
        return times >= this.defaultMinutes;
      });

      if (closestMinutes) {
        this.scrollToElementWithinParent(
          this.getOptionElementByMinutes(closestMinutes),
          true,
        );
      }
    }
  }

  scrollToElementWithinParent(el: Element | null, forceToTop?: boolean): void {
    if (!el) return; // If there's no element, exit
    const parent = el.parentElement;

    if (!parent) return; // If there's no parent, exit

    // Get bounding rectangles
    const parentRect = parent.getBoundingClientRect();
    const elementRect = el.getBoundingClientRect();

    if (forceToTop) {
      // If forceToTop is set, scroll the element to the top of its parent
      parent.scrollTop += elementRect.top - parentRect.top;
    } else {
      // Calculate necessary scroll for vertical scrolling
      if (elementRect.top < parentRect.top) {
        // If the element is above the visible area of the parent
        parent.scrollTop -= parentRect.top - elementRect.top;
      } else if (elementRect.bottom > parentRect.bottom) {
        // If the element is below the visible area of the parent
        parent.scrollTop += elementRect.bottom - parentRect.bottom;
      }
    }

    // Calculate necessary scroll for horizontal scrolling
    if (elementRect.left < parentRect.left) {
      // If the element is to the left of the visible area of the parent
      parent.scrollLeft -= parentRect.left - elementRect.left;
    } else if (elementRect.right > parentRect.right) {
      // If the element is to the right of the visible area of the parent
      parent.scrollLeft += elementRect.right - parentRect.right;
    }
  }

  selectElement(el: HTMLDivElement) {
    if (!el) return;

    const value = el.getAttribute("data-value");
    if (!value) return;
    const time = parseTime(value);
    if (time) {
      this.input.value = formatTime(time);
      this.onSelect(time);
    }
  }

  show() {
    this.timesEl.classList.remove("hidden");
    this.popperInstance.update();
  }

  hide() {
    this.timesEl.classList.add("hidden");
  }

  setTimes(times: ReturnType<typeof generateMinuteSeries>) {
    this.times = times;

    this.render();
  }

  setDefaultMinutes(minutes: number) {
    this.defaultMinutes = minutes;

    this.render();
  }

  setMinimumComparisonMinutes(minutes: number) {
    this.minimumComparisonMinutes = minutes;

    this.render();
  }

  render() {
    const options = this.times.map((time) => ({
      value: formatTime(time),
      name: formatTime(time),
      description: null,
    }));
    this.timesEl.classList.add(
      "hidden",
      "overflow-auto",
      "max-h-48",
      "bg-white",
      "border-gray-300",
      "border",
      "rounded-md",
      "sm:text-sm",
      "z-20",
    );
    this.input.parentElement?.appendChild(this.timesEl);
    this.timesEl.innerHTML = "";
    options.forEach(({ value, name, description: _description }) => {
      const option = document.createElement("div");
      option.classList.add("p-2", "cursor-pointer");
      option.setAttribute("data-value", value);
      option.setAttribute("tabindex", "-1");
      option.addEventListener("mouseenter", () => {
        this.highlightedMinutes = parseTime(value) || null;
        this.renderHighlight();
      });

      if (this.minimumComparisonMinutes === null) {
        option.innerHTML = `
        <div class="flex justify-between pr-16">
          <div>${name}</div>
        </div>
      `;
      } else {
        option.innerHTML = `
        <div class="flex justify-between">
          <div>${name}</div>
          <div class="text-gray-500">${timeDifference(
            this.minimumComparisonMinutes,
            parseTime(value) || 0,
          )}</div>
        </div>
      `;
      }
      this.timesEl.appendChild(option);
    });
    if (!this.popperInstance) {
      this.popperInstance = createPopper(this.input, this.timesEl, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ],
      });
    }

    this.popperInstance.update();
  }

  renderHighlight() {
    this.timesEl.querySelectorAll("[data-value]").forEach((el) => {
      el.classList.remove("bg-blue-600");
      el.children[0].children[0].classList.remove("text-white");
      el.children[0].children[1]?.classList.remove("text-white");
      el.children[0].children[1]?.classList.add("text-gray-500");
    });

    if (this.highlightedMinutes !== null) {
      const option = this.getOptionElementByMinutes(this.highlightedMinutes);

      if (option) {
        option.classList.add("bg-blue-600");
        option.children[0].children[0].classList.add("text-white");
        option.children[0].children[1]?.classList.add("text-white");
        option.children[0].children[1]?.classList.remove("text-gray-500");
      }
    }
  }

  getMinutesMatchingInput() {
    const inputValue = this.input.value;
    const matchedMinutes = this.times.find((minutes) => {
      return formatTime(minutes).startsWith(inputValue.trim());
    });

    return matchedMinutes;
  }

  getMinutesMatchingInputExactly() {
    const inputValue = this.input.value;
    const matchedMinutes = this.times.find((minutes) => {
      return formatTime(minutes) === inputValue.trim();
    });

    return matchedMinutes;
  }

  getOptionElementByMinutes(minutes: number) {
    const value = formatTime(minutes);

    return this.timesEl.querySelector(`[data-value="${value}"]`);
  }
}

/*
  This controller is responsible for the combination of the start and end time fields.
*/
export default class TimePairController extends Controller {
  static targets = ["start", "end"];

  declare readonly hasStartTarget: boolean;
  declare readonly hasEndTarget: boolean;
  declare readonly startTarget: HTMLInputElement;
  declare readonly endTarget: HTMLInputElement;

  declare startTimes: ReturnType<typeof generateMinuteSeries>;
  declare startTimeDropdown: TimeDropdown;
  declare endTimes: ReturnType<typeof generateMinuteSeries>;
  declare endTimeDropdown: TimeDropdown;

  connect() {
    if (!this.hasStartTarget) {
      throw new Error("No start time target found");
    }

    if (!this.hasEndTarget) {
      throw new Error("No end time target found");
    }

    const startTimeInterval = 30;
    this.startTimes = generateMinuteSeries(
      0,
      24 * 60 - startTimeInterval,
      startTimeInterval,
    );
    const endTimeInterval = 10;
    this.endTimes = generateMinuteSeries(
      0,
      24 * 60 - endTimeInterval,
      endTimeInterval,
    );

    this.startTimeDropdown = new TimeDropdown(
      this.startTarget,
      (_minutes: number) => this.updateEndTimes(),
    );
    this.startTimeDropdown.setTimes(this.startTimes);
    this.startTimeDropdown.setDefaultMinutes(getCurrentMinutes());
    this.endTimeDropdown = new TimeDropdown(
      this.endTarget,
      (minutes: number) => {},
    );
    this.endTimeDropdown.setTimes(this.endTimes);
    this.startTarget.addEventListener("input", (e) => this.updateEndTimes());
  }

  updateEndTimes() {
    const startMinutes = parseTime(this.startTarget.value);
    if (!startMinutes) return;

    const interval = 10;
    const minuteSeries = generateMinuteSeries(
      startMinutes,
      startMinutes + 24 * 60 - interval,
      interval,
    );
    this.endTimes = minuteSeries;
    this.endTimeDropdown.setMinimumComparisonMinutes(startMinutes);
    this.endTimeDropdown.setTimes(this.endTimes);
  }
}
