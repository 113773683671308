import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["showable"];
  static values = { state: Boolean };

  connect() {
    this.toggleShowable();
  }

  update(event) {
    this.stateValue = event.target.value;
    this.toggleShowable();
  }

  toggleShowable() {
    if (this.stateValue) {
      this.showShowable();
    } else {
      this.hideShowable();
    }
  }

  hideShowable() {
    this.showableTarget.classList.add("hidden");
  }

  showShowable() {
    this.showableTarget.classList.remove("hidden");
  }
}
