import { Controller } from "@hotwired/stimulus";
import { useClickOutside, useTransition } from "stimulus-use";
import { useHotkeys } from "stimulus-use/hotkeys";

export default class extends Controller {
  static targets = ["button", "options", "defaultOptions"];

  connect() {
    useClickOutside(this);
    useTransition(this, {
      element: this.optionsTarget,
      enterActive: "transition ease-out duration-100",
      enterFrom: "transform opacity-0 scale-95",
      enterTo: "transform opacity-100 scale-100",
      leaveActive: "transition ease-in duration-75",
      leaveFrom: "transform opacity-100 scale-100",
      leaveTo: "transform opacity-0 scale-95",
      hiddenClass: "hidden",
      transitioned: false,
    });
    useHotkeys(this, {
      esc: [this.close],
    });
  }

  async open() {
    this.element.classList.add("z-50");
    await this.enter();
  }

  async close() {
    await this.leave();
    if (this.hasDefaultOptionsTarget) {
      this.showOptions(this.defaultOptionsTarget);
    }
    this.element.classList.remove("z-50");
  }

  toggle() {
    if (this.transitioned) {
      this.close();
    } else {
      this.open();
    }
  }

  switchOptions(event) {
    event.preventDefault();

    this.showOptions(
      this.element.querySelector(`[data-option-id="${event.params.optionId}"`),
    );
  }

  showOptions(element) {
    this.element
      .querySelectorAll(`[data-option-id]`)
      .forEach((optionElement) => {
        optionElement.classList.add("hidden");
      });
    element.classList.remove("hidden");
  }
}
