import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const defaultChoicesOptions = {
  allowHTML: true,
};
export default class extends Controller {
  static targets = ["select"];
  static values = {
    url: String,
    autoClose: Boolean,
  };

  abortController = new AbortController();

  connect() {
    this.choices = new Choices(this.selectTarget, {
      callbackOnCreateTemplates: () => ({
        choice(classes, attr) {
          const el = Choices.defaults.templates.choice.call(
            this,
            classes,
            attr,
          );
          if (attr.customProperties.overbooked)
            el.classList.add("choices__overbooked");
          return el;
        },
      }),
      ...defaultChoicesOptions,
      ...JSON.parse(this.element.dataset.choices),
    });

    if (this.hasUrlValue) {
      this.choices.loadingText = "Loading...";

      fetch(this.urlValue, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        signal: this.abortController.signal,
      })
        .then((response) => response.json())
        .then((data) => {
          this._setChoices(data);
          window.dispatchEvent(new CustomEvent("choices:initialized"));
        });
    }

    this._updateChoices = this._updateChoices.bind(this);
    this.selectTarget.addEventListener(
      "choices:updateChoices",
      this._updateChoices,
    );

    if (this.autoCloseValue) {
      this.selectTarget.addEventListener("addItem", (event) =>
        this.choices.hideDropdown(),
      );
    }
  }

  disconnect() {
    this.abortController.abort();
    this.choices.destroy();
  }

  setChoiceByValue(value) {
    const maxItemCount = this.choices.config.maxItemCount;
    const selectedItemCount = this.choices.getValue().length;

    if (selectedItemCount < maxItemCount) {
      this.choices.setChoiceByValue(value);
    }
  }

  setChoiceFromEvent({ detail: { id } }) {
    this.setChoiceByValue(id);
  }

  clearChoices() {
    this.choices.removeActiveItems();
  }

  _setChoices(choices) {
    this.choices.removeActiveItems();
    this.choices.setChoices(choices);
  }

  _updateChoices(event) {
    this.choices.clearChoices();
    this._setChoices(event.data);
  }
}
