import { Controller } from "@hotwired/stimulus";

const getRadioInputValue = (element, name, selector) => {
  const checkedInput = element.querySelector(
    `[name=${name}\\[${selector}\\]]:checked`,
  );
  if (checkedInput) {
    return checkedInput.value;
  }
};

const isRadioInputChecked = (element, name, selector) => {
  const checkedInput = element.querySelector(
    `[name=${name}\\[${selector}\\]]:checked`,
  );
  return !!checkedInput;
};

const clearRadioInput = (element, name, selector) => {
  Array.from(
    element.querySelectorAll(`[name=${name}\\[${selector}\\]]`),
  ).forEach((input) => {
    input.checked = false;
  });
};

export default class extends Controller {
  static targets = [
    "externalPlatformOff",
    "externalPlatformMessage",
    "urlInput",
  ];
  static values = {
    externalPlatform: Boolean,
    externalPlatformAudio: Boolean,
    externalPlatformService: Boolean,
    name: String,
  };

  connect() {
    this.setValues = this.setValues.bind(this);
  }

  switchExternalPlatform() {
    this.setValues();
  }

  switchExternalPlatformAudio() {
    this.setValues();
  }

  switchExternalPlatformService() {
    this.setValues();
  }

  setValues() {
    this.externalPlatformValue = getRadioInputValue(
      this.element,
      this.nameValue,
      "external_platform",
    );
    this.externalPlatformAudioChecked = isRadioInputChecked(
      this.element,
      this.nameValue,
      "external_platform_audio",
    );
    this.externalPlatformAudioValue = getRadioInputValue(
      this.element,
      this.nameValue,
      "external_platform_audio",
    );
    this.externalPlatformServiceChecked = isRadioInputChecked(
      this.element,
      this.nameValue,
      "external_platform_service",
    );
    this.externalPlatformServiceValue = getRadioInputValue(
      this.element,
      this.nameValue,
      "external_platform_service",
    );
    this.ensureCorrectPlatform();
  }

  ensureCorrectPlatform() {
    if (
      this.externalPlatformAudioChecked &&
      this.externalPlatformServiceChecked &&
      !this.externalPlatformAudioValue &&
      !this.externalPlatformServiceValue
    ) {
      clearRadioInput(this.element, this.nameValue, "external_platform_audio");
      clearRadioInput(
        this.element,
        this.nameValue,
        "external_platform_service",
      );
      this.externalPlatformOffTarget.click();
      this.externalPlatformMessageTarget.classList.remove("hidden");
      this.urlInputTarget.value = "";
    } else {
      this.externalPlatformMessageTarget.classList.add("hidden");
    }
  }
}
