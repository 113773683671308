import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["windowLeft", "windowRight", "handler", "wrapper"];
  static values = {
    wrapperWidth: Number,
    dragging: Boolean,
  };

  connect() {
    this.updateWidth();
  }

  updateWidth() {
    this.wrapperWidthValue = this.wrapperTarget.clientWidth;
  }

  enableDragging() {
    this.draggingValue = true;
  }

  disableDragging() {
    this.draggingValue = false;
  }

  resizeVideoPanes(event) {
    if (!this.draggingValue) {
      return false;
    }

    var pointerRelativeXpos = event.clientX - this._containerOffsetLeft();
    var boxMinWidth = 200;
    var boxMaxWidth = Math.min(
      pointerRelativeXpos - 8,
      this.wrapperWidthValue - boxMinWidth,
    );

    var boxAWidth = Math.max(boxMinWidth, boxMaxWidth);
    this.windowLeftTarget.style.width = boxAWidth + "px";
    this.windowRightTarget.style.width =
      this.wrapperWidthValue - boxAWidth + "px";
    this.windowLeftTarget.style.flexGrow = 0;
  }

  _containerOffsetLeft() {
    return this.wrapperTarget.offsetLeft;
  }
}
