import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["top", "bottom", "exitAction"];
  static values = {
    enabled: Boolean,
  };

  connect() {
    this.hidden = false;
  }

  toggle() {
    if (this.enabledValue) {
      this.enabledValue = false;
      this.exitActionTarget.classList.add("hidden");
      this.topTarget.classList.remove("hidden");

      if (this.hasBottomTarget) {
        this.bottomTarget.classList.remove("hidden");
      }
    } else {
      this.enabledValue = true;
      this.exitActionTarget.classList.remove("hidden");
      this.topTarget.classList.add("hidden");

      if (this.hasBottomTarget) {
        this.bottomTarget.classList.add("hidden");
      }
    }
  }
}
