import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    id: Number,
  };

  connect() {
    if (this.choicesToInitialize > 0) {
      this.disableButtons();
      this.choicesInitialized = 0;
      this.boundEnableButtonsAfterInitialized =
        this.enableButtonsAfterInitialized.bind(this);
      window.addEventListener(
        "choices:initialized",
        this.boundEnableButtonsAfterInitialized,
      );
    }
  }

  disconnect() {
    window.removeEventListener(
      "choices:initialized",
      this.boundEnableButtonsAfterInitialized,
    );
  }

  apply() {
    this.dispatch("apply", { detail: { id: this.idValue } });
  }

  clear() {
    this.dispatch("clear");
  }

  updateId(event) {
    const value = event.target.value;
    this.idValue = parseInt(value);
  }

  enableButtonsAfterInitialized() {
    this.choicesInitialized += 1;
    if (this.choicesToInitialize == this.choicesInitialized) {
      this.enableButtons();
    }
  }

  disableButtons() {
    this.buttonTargets.map((b) => (b.disabled = true));
  }

  enableButtons() {
    this.buttonTargets.map((b) => (b.disabled = false));
  }

  get choicesToInitialize() {
    return document.querySelectorAll("[data-controller='choices']").length;
  }
}
