import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "block", "label", "content"];
  static values = {
    initial: String,
  };

  connect() {
    var self = this;
    var selectedTarget = this.inputTargets.find(
      (i) => i.value == self.initialValue,
    );
    if (selectedTarget !== undefined)
      this.highlight(this.indexOfInput(selectedTarget));
  }

  select(event) {
    this.clear();
    this.highlight(this.indexOfInput(event.target));
  }

  indexOfInput(input) {
    return parseInt(input.parentElement.dataset["key"]);
  }

  highlight(index) {
    this.blockTargets[index].classList.add(...this.selectedBlockClass);
    this.labelTargets[index].classList.add(...this.selectedLabelClass);
    this.contentTargets[index].classList.add(...this.selectedContentClass);
  }

  clear() {
    var self = this;
    this.blockTargets.forEach(function (b) {
      b.classList.add(...self.unselectedBlockClass);
      b.classList.remove(...self.selectedBlockClass);
    });
    this.labelTargets.forEach(function (l) {
      l.classList.add(...self.unselectedLabelClass);
      l.classList.remove(...self.selectedLabelClass);
    });
    this.contentTargets.forEach(function (c) {
      c.classList.add(...self.unselectedContentClass);
      c.classList.remove(...self.selectedContentClass);
    });
  }

  get selectedBlockClass() {
    return ["bg-blue-50", "border-blue-200", "z-10"];
  }

  get unselectedBlockClass() {
    return ["border-gray-200"];
  }

  get selectedLabelClass() {
    return ["text-blue-900"];
  }

  get unselectedLabelClass() {
    return ["text-gray-900"];
  }

  get selectedContentClass() {
    return ["text-blue-700"];
  }

  get unselectedContentClass() {
    return ["text-gray-500"];
  }
}
