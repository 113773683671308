import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static targets = [
    "select",
    "external",
    "asl",
    "cart",
    "typewell",
    "credentials",
  ];

  static values = {
    hasExternalPractitioners: Boolean,
    communicationType: String,
    assignedCount: Number,
    disabled: Boolean,
  };

  connect() {
    this.choices = [];
    this.maxPractitionersAllowed = 2;
    this._initializeSelects();
    this._hideAllSelects();
    this._showSelectedType();
    this._toggleCredentials();
    this._updateSelectLimits();

    this.selectTargets.forEach((element) => {
      element.addEventListener("addItem", (event) => this.addItem(event));
      element.addEventListener("removeItem", (event) => this.removeItem(event));
    });

    this._cleanUp = this._cleanUp.bind(this);
    this.element.closest("form").addEventListener("submit", this._cleanUp);

    if (this.disabledValue) {
      this._disableSelects(this._allSelectElements);
    }
  }

  disconnect() {
    this.choices.map((c) => c.destroy());
  }

  addItem(event) {
    this.assignedCountValue++;
    this._updateSelectLimits();
    this._toggleCredentials();
    this.choices.map((c) => c.hideDropdown());
  }

  removeItem(event) {
    this.assignedCountValue--;
    this._updateSelectLimits();
    this._toggleCredentials();
  }

  updateType(event) {
    this._hideAllSelects();

    this.communicationTypeValue = event.currentTarget.value;
    this._showSelectedType();
  }

  resetDropdowns() {
    this.assignedCountValue = 0;

    this.selectTargets.forEach((element) => {
      element.value = "";
      this._setMaxSelectedOptions(element, this.maxPractitionersAllowed);
    });
  }

  _cleanUp() {
    // Before the form is submitted, destroy the hidden choices dropdowns
    // otherwise they'll be included in the parameters
    const selectParents = this.aslTargets.concat(
      this.cartTargets,
      this.typewellTargets,
    );
    selectParents.forEach((element) => {
      if (this._isVisible(element)) return;

      let selectTarget = element.querySelector(".choices__inner select");
      let choiceElement = this.choices.find(
        (c) => c.passedElement.element == selectTarget,
      );
      choiceElement.destroy();
    });
  }

  _initializeSelects() {
    const maxItemCount =
      this._availableSlotCount == 0 ? 0 : this.maxPractitionersAllowed;
    this.selectTargets.forEach((element) => {
      this.choices.push(
        new Choices(element, {
          allowHTML: true,
          removeItems: true,
          removeItemButton: true,
          maxItemCount: maxItemCount,
        }),
      );
    });
    this._updateSelectLimits();
  }

  _showSelectedType() {
    switch (this.communicationTypeValue) {
      case "asl":
        this._showAslSelects();
        break;
      case "cart":
        this._showCartSelects();
        break;
      case "typewell":
        this._showTypewellSelects();
        break;
    }
  }

  _toggleCredentials() {
    this.hasExternalPractitionersValue = false;

    this.externalTargets.forEach((externalTarget) => {
      if (externalTarget.value) {
        this.hasExternalPractitionersValue = true;
      }
    });

    if (this.hasExternalPractitionersValue) {
      this._showCredentials();
    } else {
      this._hideCredentials();
    }
  }

  _showCredentials() {
    this.credentialsTarget.classList.remove("hidden");
  }

  _hideCredentials() {
    this.credentialsTarget.classList.add("hidden");
  }

  _showAslSelects() {
    this._enableSelects(this.aslTargets);
    this._removeClassFromSelects(this.aslTargets, "hidden");
  }

  _showCartSelects() {
    this._enableSelects(this.cartTargets);
    this._removeClassFromSelects(this.cartTargets, "hidden");
  }

  _showTypewellSelects() {
    this._enableSelects(this.typewellTargets);
    this._removeClassFromSelects(this.typewellTargets, "hidden");
  }

  _addClassToSelects(elements, class_name) {
    elements.forEach((element) => {
      element.classList.add(class_name);
    });
  }

  _removeClassFromSelects(elements, class_name) {
    elements.forEach((element) => {
      element.classList.remove(class_name);
    });
  }

  _enableSelects(elements) {
    elements.forEach((element) => {
      element.querySelectorAll("select").forEach((selectElement) => {
        let choicesElement = this.choices.find(
          (c) => c.passedElement.element == selectElement,
        );
        choicesElement.enable();
      });
    });
  }

  _disableSelects(elements) {
    elements.forEach((element) => {
      element.querySelectorAll("select").forEach((selectElement) => {
        let choicesElement = this.choices.find(
          (c) => c.passedElement.element == selectElement,
        );
        choicesElement.disable();
      });
    });
  }

  _hideAllSelects() {
    this._addClassToSelects(this._allSelectElements, "hidden");
    this._disableSelects(this._allSelectElements);
  }

  _updateSelectLimits() {
    this.selectTargets.forEach((element) => {
      if (this._availableSlotCount == 0) {
        this._limitSelect(element);
      } else {
        this._allowSelect(element);
      }
    });
  }

  _limitSelect(element) {
    let selectedCount = element.children.length;
    this._setMaxSelectedOptions(element, selectedCount);
  }

  _allowSelect(element) {
    this._setMaxSelectedOptions(element, this.maxPractitionersAllowed);
  }

  _setMaxSelectedOptions(element, number) {
    let choicesElement = this.choices.find(
      (c) => c.passedElement.element == element,
    );

    choicesElement.config.maxItemCount = number;
    if (number == 0) {
      choicesElement.disable();
    } else {
      choicesElement.enable();
    }
    choicesElement.clearInput();
  }

  _isVisible(el) {
    return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
  }

  get _availableSlotCount() {
    return this.maxPractitionersAllowed - this.assignedCountValue;
  }

  get _allSelectElements() {
    return this.aslTargets.concat(this.cartTargets, this.typewellTargets);
  }
}
