import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  initialize() {
    console.log("here!");
    this.supposedCurrentTime = 0;
  }

  pause() {
    this.video.play();
  }

  seeking() {
    var delta = this.video.currentTime - this.supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      this.video.currentTime = this.supposedCurrentTime;
    }
  }

  timeupdate() {
    if (!this.video.seeking) {
      this.supposedCurrentTime = this.video.currentTime;
    }
  }

  get video() {
    return this.contentTarget;
  }
}
