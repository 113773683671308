import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    var allowed_options = ["content", "placement", "trigger", "interactive"];
    var options = {
      allowHTML: true,
      appendTo: this.element,
    };

    if (this.hasContentTarget) {
      options.content = this.contentTarget;
    }

    allowed_options.forEach((option) => this.addOption(option, options));
    tippy(this.element, options);
  }

  addOption(option, options) {
    if (this.data.has(option)) {
      options[option] = this.data.get(option);
    }
  }
}
