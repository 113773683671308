import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  initialize() {
    this.changeTimer = false;
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  search(event) {
    let changeTimer = this.changeTimer;

    if (changeTimer !== false) clearTimeout(changeTimer);
    this.changeTimer = setTimeout(function () {
      event.preventDefault();
      event.target.form.requestSubmit();

      changeTimer = false;
    }, 300);
  }
}
