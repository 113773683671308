import { Controller } from "@hotwired/stimulus";

export default class WorkingHoursConfigurationController extends Controller {
  updateWeekday(event: {
    target: { checked: boolean };
    params: { weekday: string };
  }) {
    if (event.target.checked) {
      this._enableWeekday(event.params.weekday);
    } else {
      this._disableWeekday(event.params.weekday);
    }
  }

  _enableWeekday(day: string) {
    (
      document.getElementById(
        `working_hours_configuration_${day}_start_time`,
      ) as HTMLInputElement
    ).disabled = false;
    (
      document.getElementById(
        `working_hours_configuration_${day}_end_time`,
      ) as HTMLInputElement
    ).disabled = false;
  }

  _disableWeekday(day: string) {
    (
      document.getElementById(
        `working_hours_configuration_${day}_start_time`,
      ) as HTMLInputElement
    ).disabled = true;
    (
      document.getElementById(
        `working_hours_configuration_${day}_end_time`,
      ) as HTMLInputElement
    ).disabled = true;
  }
}
