export function formatTime(minutes: number): string {
  // Modulo to keep it within 24 hours (24 * 60 = 1440 minutes)
  minutes %= 1440;

  let hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  let period = "am";
  if (hours >= 12) {
    if (hours > 12) hours -= 12;
    period = "pm";
  }
  if (hours === 0) hours = 12; // Adjust for 12-hour clock

  const formattedMinutes = String(mins).padStart(2, "0");

  return `${String(hours)}:${formattedMinutes}${period}`;
}

export function parseTime(time: string): number | null {
  const regex = /^(\d{1,2}):(\d{2})(am|pm)$/i;
  const match = time.match(regex);

  if (!match) {
    return null;
  }

  let hour = parseInt(match[1], 10);
  const minute = parseInt(match[2], 10);
  const period = match[3].toLowerCase();

  // Validate the hour and minute values
  if (hour < 1 || hour > 12 || minute < 0 || minute > 59) {
    return null;
  }

  // Convert the 12-hour format to 24-hour format
  if (period === "pm" && hour !== 12) {
    hour += 12;
  } else if (period === "am" && hour === 12) {
    hour -= 12;
  }

  return hour * 60 + minute;
}

export function generateMinuteSeries(
  start: number,
  end: number,
  interval: number,
): number[] {
  const series = [];

  for (let i = start; i <= end; i += interval) {
    series.push(i);
  }

  return series;
}

export function getCurrentMinutes(): number {
  const now = new Date();

  return now.getHours() * 60 + now.getMinutes();
}

export function timeDifference(
  minimumComparisonMinutes: number,
  minutes: number,
): string {
  let difference: number;

  if (minutes >= minimumComparisonMinutes) {
    difference = minutes - minimumComparisonMinutes;
  } else {
    // Wrapping around 24 hours
    difference = 1440 - minimumComparisonMinutes + minutes;
  }

  const hours = Math.floor(difference / 60);
  const remainingMinutes = difference % 60;

  let result = "";
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? "s" : ""}`;
  }
  if (remainingMinutes > 0) {
    if (result) result += ", ";
    result += `${remainingMinutes} minute${remainingMinutes > 1 ? "s" : ""}`;
  }

  return result || "0 minutes";
}
