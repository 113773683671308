import { Controller } from "@hotwired/stimulus";

/*
This controller is for toggling the visibility of elements based upon form values.
*/
export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.element.addEventListener("input", this.update.bind(this));
    this.update();
  }

  update() {
    this.elementTargets.forEach((element) => {
      let inputName = element.dataset.inputName;
      let inputValue = element.dataset.inputValue;

      this.element
        .querySelectorAll('[name="' + inputName + '"]')
        .forEach((input) => {
          if (input.value == inputValue) {
            element.classList.remove("hidden");
          } else {
            element.classList.add("hidden");
          }
        });
    });
  }
}
