import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";

export default class extends Controller {
  static targets = ["panel"];

  connect() {
    this.dismiss = this.dismiss.bind(this);
    this.escapeKeyListener = this.escapeKeyListener.bind(this);
    this.disconnect = this.disconnect.bind(this);

    useTransition(this, {
      element: this.panelTarget,
      enterActive: "transform ease-out duration-300 transition",
      enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
      enterTo: "translate-y-0 opacity-100 sm:translate-x-0",
      leaveActive: "transition ease-in duration-100",
      leaveFrom: "opacity-100",
      leaveTo: "opacity-0",
    });

    this.boundEscapeKeyListener = this.escapeKeyListener.bind(this);
    window.addEventListener("keydown", this.boundEscapeKeyListener);

    setTimeout(() => {
      this.dismiss();
    }, 5000);

    setTimeout(() => {
      this.enter();
    });
  }

  escapeKeyListener(event) {
    if (event.key === "Escape") {
      this.dismiss();
    }
  }

  async dismiss() {
    await this.leave();
    this.element.remove();
  }

  disconnect() {
    window.removeEventListener("keydown", this.boundEscapeKeyListener);
  }
}
