import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item"];

  connect() {}

  search(e) {
    e.preventDefault();
    const search = e.target.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      if (item.innerText.toLowerCase().includes(search)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    });
  }
}
