import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["transcript", "video"];

  connect() {
    this.updateTranscriptHeight();
    this.startRefreshing();
  }

  disconnect() {
    this.stopRefreshing();
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.processOperations();
    }, 100);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  processOperations() {
    let content = "";
    let filtered = this.operations.filter(
      (operation) =>
        Date.parse(operation.created_at) / 1000 <
        Date.parse(this.startTime) / 1000 + this.videoTarget.currentTime,
    );

    filtered.forEach((element) => {
      let kind = element.kind;

      switch (kind) {
        case "insert": {
          let newContent =
            content.substring(0, element.data.offset) + element.data.text;

          if (element.data.offset < content.length) {
            newContent += content.substring(element.data.offset);
          }

          content = newContent;

          break;
        }
        case "remove": {
          let newContent = content.substring(0, element.data.offset);

          if (element.data.offset + element.data.text.length < content.length) {
            newContent += content.substring(
              element.data.offset + element.data.text.length,
            );
          }

          content = newContent;

          break;
        }
      }
    });

    this.transcriptTarget.value = content;
    this.transcriptTarget.scrollTop = this.transcriptTarget.scrollHeight;
  }

  updateTranscriptHeight() {
    if (this.syncHeights) {
      this.transcriptTarget.style.height = this.videoTarget.offsetHeight + "px";
    }
  }

  get startTime() {
    return this.data.get("startTime");
  }

  get operations() {
    return JSON.parse(this.data.get("operations"));
  }

  get syncHeights() {
    return this.data.get("syncHeights");
  }
}
