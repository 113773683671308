import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="file-upload"
export default class extends Controller {
  static targets = [
    "fileField",
    "fileName",
    "uploadedFile",
    "deletedFileField",
  ];

  connect() {}

  updateFileName() {
    this.fileNameTarget.innerHTML = this.fileNames();
  }

  delete({ params: { fileId } }) {
    this.deletedFileFieldTarget.value = fileId;
    this.uploadedFileTarget.remove();
  }

  fileNames() {
    return Array.from(this.fileList)
      .map((file) => file.name)
      .join(", ");
  }

  get fileList() {
    return Array.from(this.fileFieldTarget.files);
  }
}
