import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entity"];

  open() {
    this.entityTarget.classList.remove("hidden");
  }

  close() {
    this.entityTarget.classList.add("hidden");
  }
}
