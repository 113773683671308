import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["file", "filename"];

  connect() {
    this.defaultFilename = this.filenameTarget.innerText;
  }

  open() {
    this.fileTarget.click();
  }

  change() {
    if (this.fileTarget.files[0]) {
      this.filenameTarget.innerText = this.fileTarget.files[0].name;
    } else {
      this.filenameTarget.innerText = this.defaultFilename;
    }
  }
}
