import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectable"];

  selectAll(event) {
    const selectAllCheckbox = event.currentTarget;
    this.toggleSelected(selectAllCheckbox.checked);
  }

  toggleSelected(checked) {
    this.selectableTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }
}
