import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (!window.FrontChat) {
      return;
    }

    this.unbindFrontChat = FrontChat("onUnreadChange", () => {
      FrontChat("show");
    });
  }

  disconnect() {
    if (!window.FrontChat || !this.unbindFrontChat) {
      return;
    }

    this.unbindFrontChat();
  }

  openSupportChat() {
    FrontChat("show");
  }
}
