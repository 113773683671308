import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "blankVideo",
    "currentVideo",
    "fileInput",
    "newVideo",
    "removeVideoInput",
  ];

  connect() {
    const self = this;

    this.fileInputTarget.addEventListener("change", () => {
      if (this.fileInputTarget.files && this.fileInputTarget.files[0]) {
        let blobURL = URL.createObjectURL(this.fileInputTarget.files[0]);
        this.newVideoTarget.querySelector("video").src = blobURL;
        this.blankVideoTarget.classList.add("hidden");
        this.currentVideoTarget.classList.add("hidden");
        this.newVideoTarget.classList.remove("hidden");
        this.removeVideoInputTarget.value = false;
      }
    });
  }

  remove() {
    this.blankVideoTarget.classList.remove("hidden");
    this.currentVideoTarget.classList.add("hidden");
    this.newVideoTarget.classList.add("hidden");

    this.removeVideoInputTarget.value = true;
  }
}
