import { Controller } from "@hotwired/stimulus";

const hyphenToPascalCase = (string: string) => {
  return string
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
};

/*
  This controller is responsible for generic class handling.
*/
export default class ClassController extends Controller {
  static targets = ["element"];
  static values = {
    states: String,
  };

  declare readonly elementTargets: HTMLElement[];
  declare readonly statesValue: string;
  declare readonly hasStatesValue: boolean;

  declare allStates: string[];
  declare activeStates: string[];

  connect() {
    if (!this.hasStatesValue) {
      throw new Error("ClassController requires a states value");
    }

    this.allStates = this.statesValue.split(" ");
    this.activeStates = [];
  }

  addState({ params: { state } }: Event & { params: { state: string } }) {
    if (
      state &&
      this.allStates.includes(state) &&
      !this.activeStates.includes(state)
    ) {
      this.activeStates.push(state);
    }

    this.render();
  }

  removeState({ params: { state } }: Event & { params: { state: string } }) {
    this.activeStates = this.activeStates.filter((state) => state !== state);

    this.render();
  }

  render() {
    this.elementTargets.forEach((element) => {
      this.allStates.forEach((state) => {
        const stateDataSelector = hyphenToPascalCase(state);
        const onClass = element.dataset[`class${stateDataSelector}OnClass`];
        const offClass = element.dataset[`class${stateDataSelector}OffClass`];

        if (this.activeStates.includes(state)) {
          if (onClass) {
            element.classList.add(onClass);
          }
          if (offClass) {
            element.classList.remove(offClass);
          }
        } else {
          if (onClass) {
            element.classList.remove(onClass);
          }
          if (offClass) {
            element.classList.add(offClass);
          }
        }
      });
    });
  }
}
