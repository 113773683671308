import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frequencySelector", "date", "weekly", "monthly"];
  static values = { frequency: String };

  initialize() {
    this._setFrequencySelectorValue();
  }

  _setFrequencySelectorValue() {
    if (!this.frequencyValue) {
      this.frequencyValue = "no-repeat";
    }

    this.frequencySelectorTarget.value = this.frequencyValue;
    this.toggleVisible();
  }

  toggleVisible() {
    this._hideAll();

    if (this._frequencyIsRecurring()) {
      this._show(this.dateTarget);

      switch (this.frequencySelectorTarget.value) {
        case "monthly":
          this._show(this.monthlyTarget);
          break;
        case "weekly":
          this._show(this.weeklyTarget);
          break;
      }
    }
  }

  _frequencyIsRecurring() {
    return this.frequencySelectorTarget.value != "no-repeat";
  }

  _show(element) {
    element.classList.remove("hidden");
  }

  _hideAll() {
    let repeatElements = [
      this.dateTarget,
      this.weeklyTarget,
      this.monthlyTarget,
    ];

    repeatElements.forEach((element) => {
      element.classList.add("hidden");
    });
  }
}
