import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["backdrop", "sidebar", "content"];

  connect() {
    this.hide();
  }

  hide() {
    this.leave();
  }

  show() {
    this.enter();
  }

  enter() {
    enter(this.backdropTarget);
    enter(this.sidebarTarget);
    enter(this.contentTarget);
    [this.backdropTarget, this.sidebarTarget, this.contentTarget].forEach(
      (el) => el.classList.remove("hidden"),
    );
  }

  leave() {
    leave(this.contentTarget).then(() => {
      leave(this.sidebarTarget).then(() => {
        leave(this.backdropTarget).then(() => {
          [this.backdropTarget, this.sidebarTarget, this.contentTarget].forEach(
            (el) => el.classList.add("hidden"),
          );
        });
      });
    });
  }
}
