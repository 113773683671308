import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = [];

  load(event) {
    let url = event.target.value;
    Turbo.visit(url);
  }
}
