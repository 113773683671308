import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    changeText: Boolean,
  };

  async copy(e) {
    if (!navigator.clipboard) return;

    const { content } = e.params;
    await navigator.clipboard.writeText(content);

    const event = new CustomEvent("clipboard:copy", { detail: { content } });
    window.dispatchEvent(event);

    if (this.changeTextValue) {
      const originalText = this.element.innerHTML;
      this.element.innerHTML = "Copied!";
      setTimeout(() => {
        this.element.innerHTML = originalText;
      }, 5000);
    }
  }
}
