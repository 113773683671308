import { Controller } from "@hotwired/stimulus";

const getDataValue = (element) => {
  return parseInt(element.dataset.value);
};

export default class extends Controller {
  static targets = ["radio", "icon"];

  connect() {
    this.value = getDataValue(this.element);
    this.onClasses = this.element.dataset.onClasses;
    this.offClasses = this.element.dataset.offClasses;
    this.previewValue = null;
  }

  stopPreview() {
    this.previewValue = null;
    this.render();
  }

  preview(event) {
    this.previewValue = getDataValue(event.currentTarget);
    this.render();
  }

  setValue(event) {
    const value = getDataValue(event.currentTarget);
    this.value = value;
    this.previewValue = null;
    this.radioTargets.forEach((radioTarget) => {
      radioTarget.checked = getDataValue(radioTarget) == value;
    });
    this.render();
  }

  render() {
    const valueForRendering = this.previewValue
      ? this.previewValue
      : this.value;

    this.iconTargets.forEach((iconTarget) => {
      const iconTargetValue = getDataValue(iconTarget);

      if (iconTargetValue <= valueForRendering) {
        this.toggleOn(iconTarget);
      } else {
        this.toggleOff(iconTarget);
      }
    });
  }

  toggleOn(element) {
    element.classList.add(this.onClasses);
    element.classList.remove(this.offClasses);
  }

  toggleOff(element) {
    element.classList.add(this.offClasses);
    element.classList.remove(this.onClasses);
  }
}
