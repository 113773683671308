import Daily from "@daily-co/daily-js";
import { Controller } from "@hotwired/stimulus";

import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = [
    "participantVideoElement",
    "participantAudioElement",
    "participantVolumeElement",
    "roomVideoElement",
    "roomAudioElement",
    "roomVolumeElement",
    "participantVideoEnabled",
    "participantAudioEnabled",
  ];
  static values = {
    url: String,
    token: String,
    participantId: String,
    meetingId: String,
  };

  connect() {
    if (this.urlValue == "") return;

    this.call = Daily.createCallObject({
      url: this.urlValue,
      token: this.tokenValue,
      videoSource: false,
      audioSource: false,
    });

    this.call.join();
    this.call.on("joined-meeting", () => {
      this.audioContext = new AudioContext();
      this.subscription = consumer.subscriptions.create(
        {
          channel: "MeetingChannel",
          meeting_id: this.meetingIdValue,
        },
        {
          received: this.onMeetingChannelReceive.bind(this),
        },
      );

      this.call.on("participant-updated", ({ participant }) => {
        if (!this._isCurrentParticipant(participant)) return;

        console.log("participant-updated", participant);

        this.participantSessionId = participant.session_id;

        const participants = this.call.participants();

        if (
          this.roomCameraParticipantId &&
          this.roomCameraTrackName &&
          this.hasRoomVideoElementTarget
        ) {
          const participant = participants[this.roomCameraParticipantId];
          if (participant) {
            const track =
              participant.tracks[this.roomCameraTrackName]?.persistentTrack;

            if (track) {
              this.roomVideoElementTarget.srcObject = new MediaStream([track]);
            }
          }
        }

        if (
          this.roomMicrophoneParticipantId &&
          this.roomMicrophoneTrackName &&
          this.hasRoomAudioElementTarget
        ) {
          const participant = participants[this.roomMicrophoneParticipantId];
          if (participant) {
            const track =
              participant.tracks[this.roomMicrophoneTrackName]?.persistentTrack;

            if (track) {
              this.roomAudioElementTarget.srcObject = new MediaStream([track]);
              this._visualizeAudio(
                this.roomAudioElementTarget.srcObject,
                this.roomVolumeElementTarget,
              );
            }
          }
          this.roomAudioElementTarget.muted = true;
        }

        const participantVideo = participant.tracks.video;
        this.participantVideoEnabledTarget.checked =
          participantVideo.state === "playable";
        const participantVideoTrack = participantVideo.persistentTrack;
        if (participantVideoTrack) {
          this.participantVideoElementTarget.srcObject = new MediaStream([
            participantVideoTrack,
          ]);
        }

        const participantAudio = participant.tracks.audio;
        this.participantAudioEnabledTarget.checked =
          participantAudio.state === "playable";
        const participantAudioTrack = participantAudio.persistentTrack;
        if (participantAudioTrack) {
          this.participantAudioElementTarget.srcObject = new MediaStream([
            participantAudioTrack,
          ]);
          this._visualizeAudio(
            this.participantAudioElementTarget.srcObject,
            this.participantVolumeElementTarget,
          );
        }
      });
    });

    this.call.on("left-meeting", () => {
      this.audioContext.close();
    });
  }

  onMeetingChannelReceive({ event, data: data }) {
    if (event === "update_session") {
      const {
        room_camera_participant_id: roomCameraParticipantId,
        room_camera_track_name: roomCameraTrackName,
        room_microphone_participant_id: roomMicrophoneParticipantId,
        room_microphone_track_name: roomMicrophoneTrackName,
      } = data;

      this.roomCameraParticipantId = roomCameraParticipantId;
      this.roomCameraTrackName = roomCameraTrackName;
      this.roomMicrophoneParticipantId = roomMicrophoneParticipantId;
      this.roomMicrophoneTrackName = roomMicrophoneTrackName;
    }
  }

  disconnect() {
    if (this.call === undefined) return;

    this.call.leave();
    this.call.destroy();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateDevice({ target, params }) {
    const actionType = params.actionType;
    const value = target.value || "none";

    this._sendMessageToParticipant({
      action: actionType,
      value: value,
    });
  }

  toggleDevice({ target, params }) {
    const actionType = params.actionType;

    this._sendMessageToParticipant({
      action: actionType,
      value: target.checked,
    });
  }

  toggleRoomAudio() {
    this.participantAudioElementTarget.muted =
      !this.participantAudioElementTarget.muted;
    this.roomAudioElementTarget.muted = !this.roomAudioElementTarget.muted;
  }

  _sendMessageToParticipant(message) {
    this.call.sendAppMessage(message, this.participantSessionId);
  }

  _isCurrentParticipant(participant) {
    return participant.user_id == this.participantIdValue;
  }

  async _visualizeAudio(stream, meterEl) {
    const mediaStreamAudioSourceNode =
      this.audioContext.createMediaStreamSource(stream);
    const analyserNode = this.audioContext.createAnalyser();
    mediaStreamAudioSourceNode.connect(analyserNode);

    const pcmData = new Float32Array(analyserNode.fftSize);
    const onFrame = () => {
      analyserNode.getFloatTimeDomainData(pcmData);
      let sumSquares = 0.0;
      for (const amplitude of pcmData) {
        sumSquares += amplitude * amplitude * 100;
      }
      meterEl.value = Math.sqrt(sumSquares / pcmData.length);
      window.requestAnimationFrame(onFrame);
    };
    window.requestAnimationFrame(onFrame);
  }
}
