import { Controller } from "@hotwired/stimulus";
import Litepicker from "litepicker";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    beginDateLimit: String,
    endDateLimit: String,
  };

  connect() {
    new Litepicker({
      element: this.element,
      format: "MM/DD/YYYY",
      firstDay: 7,
      setup: (picker) => {
        picker.on("selected", (date1, date2) => {
          setTimeout(() => {
            this.broadcastEvent();
          }, 0);
        });
      },
      lockDaysFilter: (date1, date2, pickedDates) => {
        if (
          this.hasBeginDateLimitValue &&
          new Date(this.beginDateLimitValue) >
            new Date(date1.format("YYYY-MM-DD"))
        ) {
          return true;
        }

        if (
          this.hasEndDateLimitValue &&
          new Date(this.endDateLimitValue) <
            new Date(date1.format("YYYY-MM-DD"))
        ) {
          return true;
        }
      },
    });
  }

  broadcastEvent() {
    this.dispatch("pick");
  }
}
